export const getspHostUrl = (): string | null => {
    const urlBasedSpHostUrl = extractSpHostUrl(new URLSearchParams(window.location.search))

    if (urlBasedSpHostUrl) return urlBasedSpHostUrl

    /**
     * Side loaded cache sphostUrl for auth purposes @todo: Improve this!
     * persist:global
     */
    const storedRootValue = localStorage?.getItem?.("persist:global")

    if (storedRootValue) {
        try {
            let values = JSON.parse(storedRootValue)
            return decodeURI(values["spHostUrl"].split('"').join(""))
        } catch (error) {
            console.log(
                "Could not determine SP host url from cache in 'getspHostUrl' call. ",
                error
            )
        }
    }

    return null
}

/**
 * Fetch SphostUrl arguemnent from URL.
 * Extra Sanity check for compatible URL.
 */
export const extractSpHostUrl = (urlSearchParams: URLSearchParams): string | null => {
    /**
     * Possible solution URL identifiers.
     */
    const possibleKeys = ["sphosturl", "solutionurl"]

    for (const [key, value] of Array.from(urlSearchParams.entries())) {
        if (
            possibleKeys.indexOf(key.toLowerCase()) !== -1 &&
            typeof value === "string" &&
            value !== "" &&
            value.toLowerCase().indexOf("https") !== -1
        )
            return value
    }

    return null
}

/**
 * Extracts non-empty GET parameters from URL.
 */
export const extractGetParameter = (
    urlSearchParams: URLSearchParams,
    parameterNames: string[]
): string | null => {
    for (const [key, value] of Array.from(urlSearchParams.entries())) {
        if (
            parameterNames.indexOf(key.toLowerCase()) !== -1 &&
            typeof value === "string" &&
            value !== ""
        )
            return value
    }

    return null
}

export const isEnv = (envTarget: string): boolean =>
    process.env.REACT_APP_STAGE?.toLowerCase?.() === envTarget?.toLowerCase?.()

export const isDev = (): boolean => process.env.REACT_APP_STAGE?.toLowerCase?.() === "development"
