import { RootState } from "./store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getspHostUrl } from "../util/common"

export interface IGlobalState {
    spHostUrl: string | null
}

export const initialGlobalState: IGlobalState = {
    spHostUrl: getspHostUrl()
}

const globalSlice = createSlice({
    name: "global",
    initialState: initialGlobalState,
    reducers: {
        setspHostUrl: (state, action: PayloadAction<string>) => {
            state.spHostUrl = action.payload
        }
    }
})

export const { setspHostUrl } = globalSlice.actions

export const globalSelector = (state: RootState) => state.global
const globalReducer = globalSlice.reducer
export default globalReducer
