import * as React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"
import { InstallationTheme } from "@workpoint/components/lib/components/installation/InstallationTheme"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AppRoutes } from "../routes"
import { extractGetParameter } from "../../util/common"
import { useState } from "react"
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar"
import { IButtonStyles, PrimaryButton } from "@fluentui/react/lib/Button"
import { Text } from "@fluentui/react/lib/Text"
import { Stack } from "@fluentui/react/lib/Stack"

export const InstallSignin = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const [showUninstallMessage, setShowUninstallMessage] = useState<boolean>(
        !!extractGetParameter(searchParams, ["workpoint365appuninstalled"])
    )

    const dismissUninstallMessage = () => {
        setShowUninstallMessage(false)
        /**
         * Remove single-use uninstall message from URL
         */
        window.history.replaceState({}, "", window.location.origin + window.location.pathname)
    }

    const signinButton: Partial<IButtonStyles> = {
        root: {
            width: "max-content",
            alignSelf: "center",
            marginTop: "24px"
        }
    }

    return (
        <WPInstallationContainer>
            <HeaderContainer>
                <img
                    alt=""
                    style={{ marginRight: "auto" }}
                    src="https://workpoint.azureedge.net/Images/workpoint.png"
                />
            </HeaderContainer>
            <ContentContainer>
                {showUninstallMessage && (
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                        onDismiss={dismissUninstallMessage}
                        dismissButtonAriaLabel="Close"
                    >
                        WorkPoint Apps have been uninstalled
                    </MessageBar>
                )}
                <Headline>
                    {intl.formatMessage({
                        defaultMessage: "Welcome to WorkPoint",
                        id: "install-welcome-title"
                    })}
                </Headline>
                <Description>
                    {intl.formatMessage({
                        defaultMessage:
                            "Welcome to WorkPoint installation wizard, which will guide you through the installation of WorkPoint in a few easy steps. In order to use the wizard, you will have to sign in to your account.",
                        id: "install-welcome-desc"
                    })}
                </Description>
                <PrimaryButton
                    text={intl.formatMessage({
                        defaultMessage: "Run WorkPoint installation wizard",
                        id: "install-run-wizard"
                    })}
                    onClick={() => navigate(AppRoutes.Installer)}
                    styles={signinButton}
                    style={{ marginTop: "24px" }}
                />
            </ContentContainer>
        </WPInstallationContainer>
    )
}

const HeaderContainer = styled.div`
    grid-area: header;
    box-shadow: 1px 1px 3px lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0 10px;
`

const WPInstallationContainer = styled.div`
    display: grid;
    grid-template-areas:
        "header"
        "stepcontrols";
    grid-template-rows: 60px calc(100% - 60px);
    grid-template-columns: 100%;
    height: 100vh;
    max-height: 100%;
`

const ContentContainer = styled(Stack)`
    grid-area: stepcontrols;
    padding: 8px 30%;
`

const Description = styled(Text)`
    font-family: Segoe UI;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: ${InstallationTheme.palette.neutralSecondary};
`
const Headline = styled(Text)`
    font-family: Segoe UI;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    padding-top: 24px;
    color: ${InstallationTheme.palette.blue};
`
