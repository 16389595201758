import { useMsal } from "@azure/msal-react"
import React from "react"
import { useIntl } from "react-intl"
import { Provider } from "react-redux"
import NotificationPane from "@workpoint/components/lib/components/notification/NotificationPane"
import { configureNotificationStore } from "@workpoint/components/lib/components/notification/store/store"
import { useAppTitle } from "../../store/hooks/useAppTitle"
import { useControlClient } from "../../store/hooks/useControlClient"
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider"

export const NotificationViewer: React.FC = () => {
    const { accounts } = useMsal()
    const client = useControlClient()
    const intl = useIntl()

    const { apiClient } = useApiClient()

    const store = configureNotificationStore(
        apiClient,
        apiClient.solutionUrl,
        client,
        accounts?.[0]?.username
    )

    const urlParams = new URLSearchParams(window.location.search.toLowerCase())
    const currentUICultureName = urlParams.get("currentuiculturename") ?? "en"
    const currentCultureName = urlParams.get("currentculturename") ?? "en"
    const timezoneGet = urlParams.get("timezone")

    let timezone = undefined
    if (timezoneGet) timezone = parseInt(timezoneGet)

    useAppTitle(
        intl.formatMessage({
            id: "notification-title",
            defaultMessage: "Notifications"
        })
    )

    return (
        <Provider store={store}>
            <NotificationPane
                currentUICultureName={currentUICultureName}
                currentCultureName={currentCultureName}
                timezone={timezone}
            />
        </Provider>
    )
}
