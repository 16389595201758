import { ISPFXContext } from "@pnp/sp"
import { LambdaFetchClient } from "@workpoint/components/lib/helpers/clientHelper"
import { PublicClientApplication } from "@azure/msal-browser/dist/app/PublicClientApplication"
import { WPClient } from "@workpoint/components/lib/models/WPClient"
import { ApiClient, getWpApi } from "@workpoint/components/lib/clients/ApiClient"
import { initApplicationInsights } from "@workpoint/components/lib/helpers/insights"
import { getspHostUrl } from "../util/common"
import { LogLevel } from "@azure/msal-common"
import {
    BrowserCacheLocation,
    Configuration,
    InteractionRequiredAuthError,
    RedirectRequest,
    SilentRequest
} from "@azure/msal-browser"
import { ApiClientBase } from "@workpoint/components/lib/clients/ApiClientBase"

const spHostUrl = getspHostUrl() ?? ""
const wpApiDefinition = getWpApi()

initApplicationInsights(
    wpApiDefinition.insightsKey,
    "WorkPointEmbed",
    process.env.REACT_APP_VERSION!,
    spHostUrl,
    {
        enableAutoRouteTracking: true
    }
)

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID as string,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: document.location.origin,
        postLogoutRedirectUri: `${document.location.href}` // Parameters needed for switching accounts in process.
    },
    cache: {
        storeAuthStateInCookie: false,
        cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: any, containsPii: boolean) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        console.info(message)
                        return
                    case LogLevel.Verbose:
                        console.debug(message)
                        return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                    default:
                        return
                }
            }
        }
    }
}

export const msalInstance = new PublicClientApplication(msalConfig)

const getToken = async (scopes: string[]): Promise<string> => {
    const activeAccount = msalInstance.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts()

    if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */

        const authPayload: RedirectRequest = {
            scopes
        }

        await msalInstance.acquireTokenRedirect(authPayload)

        return ""
    }

    const silentAuthPayload: SilentRequest = {
        scopes,
        account: activeAccount || accounts[0]
    }

    const authResult = await msalInstance.acquireTokenSilent(silentAuthPayload).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            msalInstance.acquireTokenRedirect({ scopes })
            return
        }
        return undefined
    })

    return authResult?.accessToken || ""
}

export const getApiClientBase = () => {
    const wp = new WPClient(() => getToken(wpApiDefinition.scopes.split(";")))

    const apiClient = new ApiClientBase(wpApiDefinition, wp)

    return apiClient
}

export const getApiClient = (url: string, locale?: string) => {
    const accounts = msalInstance.getAllAccounts()
    const spClient = new LambdaFetchClient(
        async () => await getToken([`https://${new URL(url).hostname}/.default`])
    )

    const wp = new WPClient(() => getToken(wpApiDefinition.scopes.split(";")))

    const pnPjsSetup = {
        spConfig: {
            aadTokenProviderFactory: {
                getTokenProvider: async () => ({
                    getToken: async (resource: string) => {
                        return await getToken([`https://${new URL(url).hostname}/.default`])
                    }
                })
            },
            pageContext: {
                web: { absoluteUrl: "" },
                legacyPageContext: {
                    formDigestTimeoutSeconds: 0,
                    formDigestValue: ""
                }
            },
            spfxContext: {
                pageContext: {
                    legacyPageContext: {
                        userPrincipalName: accounts?.[0].username
                    },
                    web: {
                        absoluteUrl: url
                    }
                }
            }
        } as ISPFXContext,
        graphConfig: {
            aadTokenProviderFactory: {
                getTokenProvider: async () => ({
                    getToken: async (resource: string) => {
                        return await getToken(process.env.REACT_APP_GRAPH_SCOPES!.split(";"))
                    }
                })
            },
            pageContext: {
                web: { absoluteUrl: "" },
                legacyPageContext: {
                    formDigestTimeoutSeconds: 0,
                    formDigestValue: ""
                }
            }
        } as ISPFXContext
    }

    const apiClient = new ApiClient(
        url,
        url,
        wpApiDefinition,
        wp,
        pnPjsSetup,
        spClient as any,
        locale,
        locale
    )

    apiClient.connectSignalR()

    return apiClient
}

export const updateApiClient = (apiClient: ApiClient, url: string) => {
    const pnPjsSetup = {
        spConfig: {
            aadTokenProviderFactory: {
                getTokenProvider: async () => ({
                    getToken: async (resource: string) => {
                        return await getToken([`https://${new URL(url).hostname}/.default`])
                    }
                })
            },
            pageContext: {
                web: { absoluteUrl: "" },
                legacyPageContext: {
                    formDigestTimeoutSeconds: 0,
                    formDigestValue: ""
                }
            }
        } as ISPFXContext,
        graphConfig: {
            aadTokenProviderFactory: {
                getTokenProvider: async () => ({
                    getToken: async (resource: string) => {
                        return await getToken(process.env.REACT_APP_GRAPH_SCOPES!.split(";"))
                    }
                })
            },
            pageContext: {
                web: { absoluteUrl: "" },
                legacyPageContext: {
                    formDigestTimeoutSeconds: 0,
                    formDigestValue: ""
                }
            }
        } as ISPFXContext
    }

    apiClient.set(url, url, pnPjsSetup)

    return apiClient
}
