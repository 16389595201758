import { AuthenticatedTemplate, useMsal } from "@azure/msal-react"
import { useIntl } from "react-intl"
import styled from "styled-components"
import { SignOutButton } from "./SignOutButton "
import { Stack } from "@fluentui/react/lib/Stack"

export const UserMenu: React.FC = (props) => {
    const { accounts } = useMsal()
    const intl = useIntl()
    return (
        <Stack horizontal>
            <AuthenticatedTemplate>
                <div>
                    <SignedInUserMessage>
                        {intl.formatMessage(
                            { id: "hello-username", defaultMessage: "Hello {username}" },
                            { username: accounts?.[0]?.name }
                        )}
                    </SignedInUserMessage>
                    <SignOutButton />
                </div>
            </AuthenticatedTemplate>
        </Stack>
    )
}

const SignedInUserMessage = styled.span`
    height: 32px;
    display: inline-block;
    vertical-align: middle;
    color: white;
`
