import styled from "styled-components"
import WorkPointTheme from "@workpoint/components/lib/constants"
import React from "react"
import { UserMenu } from "./user/UserMenu"
import { Link as RouterLink } from "react-router-dom"
import { Stack } from "@fluentui/react/lib/Stack"

export const pageHeaderHeight = 50

interface IPageHeaderProps {
    title: string
}

const PageHeader: React.FC<IPageHeaderProps> = (props) => {
    return (
        <HeaderStack horizontal>
            <NonStyledLink to="/">
                <Image src={`${process.env.PUBLIC_URL}/assets/wpLogo.png`} alt="WorkPoint Logo" />
            </NonStyledLink>
            <NonStyledLink to="/">
                <Stack>
                    <CompanyTitle>WORKPOINT</CompanyTitle>
                    <Title id="title">{props.title.toUpperCase()}</Title>
                </Stack>
            </NonStyledLink>
            <Stack.Item grow shrink>
                {" "}
            </Stack.Item>
            <Stack.Item disableShrink>
                <UserMenu />
            </Stack.Item>
        </HeaderStack>
    )
}

const Image = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 30px;
    margin-right: 12px;
`

const CompanyTitle = styled.span`
    color: white;
    font-family: Calibri;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
`

const Title = styled.span`
    color: white;
    font-family: Calibri;
    font-size: 16px;
    font-weight: bold;
`

const HeaderStack = styled(Stack)`
    background-color: ${WorkPointTheme.palette.blue};
    height: ${pageHeaderHeight}px;
    display: flex;
    align-items: center;
`

const NonStyledLink = styled(RouterLink)`
    text-decoration: none;
`

export default PageHeader
