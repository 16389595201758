import { InteractionType } from "@azure/msal-browser"
import { useMsalAuthentication } from "@azure/msal-react"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { extractSpHostUrl } from "../../util/common"
import { globalSelector, setspHostUrl } from "../globalReducer"
import { useAppDispatch, useAppSelector } from "../hooks"

export function useAuthenticatedApp() {
    /**
     * Autologin of user.
     */
    useMsalAuthentication(InteractionType.Redirect, {
        scopes: [process.env.REACT_APP_WPAPI_SCOPES as string],
        redirectUri: document.location.origin + document.location.pathname
    })

    const [searchParams] = useSearchParams()
    const { spHostUrl } = useAppSelector(globalSelector)
    const dispatch = useAppDispatch()

    const getArgumentspHostUrl = extractSpHostUrl(searchParams)

    /**
     * Update spHostUrl if get param is other than already provided.
     * In a useEffect to avoid clashing with updates from the <SolutionUrl> component state.
     */
    useEffect(() => {
        if (!!getArgumentspHostUrl) {
            const loweredUrlspHostUrl = getArgumentspHostUrl.trim().toLowerCase()
            const loweredspHostUrl = spHostUrl?.trim()?.toLowerCase()

            if (loweredUrlspHostUrl !== "" && loweredUrlspHostUrl !== loweredspHostUrl) {
                dispatch(setspHostUrl(getArgumentspHostUrl))
            }
        }
    }, [getArgumentspHostUrl, spHostUrl, dispatch])

    return { spHostUrl }
}
