import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
export const reactPlugin = new ReactPlugin()
export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    }
})
appInsights.loadAppInsights()
