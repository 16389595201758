import React from "react"
import { useIntl } from "react-intl"
import { AppContainer, Content } from "./AppChrome"
import PageHeader from "./PageHeader"

export const NotFound: React.FC<any> = () => {
    const intl = useIntl()
    return (
        <AppContainer>
            <PageHeader
                title={intl.formatMessage({
                    id: "administration",
                    defaultMessage: "Embed"
                })}
            />
            <Content>
                <p>
                    {intl.formatMessage({
                        id: "not-found",
                        defaultMessage: "Nothing could be found for the URL you requested"
                    })}
                </p>
            </Content>
        </AppContainer>
    )
}
