import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import storage from "redux-persist/lib/storage"
import { PersistConfig } from "redux-persist/lib/types"
import { RequestState } from "../models/RequestState"
import globalReducer from "./globalReducer"

export interface AsyncModelState {
    status: RequestState | undefined
}

const globalPersistConfig: PersistConfig<any> = {
    key: "global",
    version: 1,
    storage,
    stateReconciler: autoMergeLevel2,
    throttle: 500
}

/**
 * @todo: Handle expirations, possibly with transforms => https://github.com/rt2zz/redux-persist#transforms
 * @todo: Keys relevant for a solution or user should be reset when the solution or user changes!
 */
const appReducer = combineReducers({
    global: persistReducer(globalPersistConfig, globalReducer)
})

/**
 * For clearing app specific state (reset whole application on user / solution change), use this approach.
 * Wrap the appReducer in a rootReducer and listen for "logout / change spHostUrl" actions and clear relevant state.
 */
export const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST"]
            }
        })
})

export let persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
