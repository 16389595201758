import { AuthenticatedTemplate } from "@azure/msal-react"
import { Outlet } from "react-router-dom"
import { useAuthenticatedApp } from "../../store/hooks/useAuthenticatedApp"

function AuthenticatedApp(): JSX.Element {
    const { spHostUrl } = useAuthenticatedApp()

    return (
        <div key={spHostUrl}>
            <AuthenticatedTemplate>
                <Outlet />
            </AuthenticatedTemplate>
        </div>
    )
}

export default AuthenticatedApp
