import { useSearchParams } from "react-router-dom"
import { ControlClient } from "@workpoint/components/lib/models/ControlClient"
import { extractGetParameter } from "../../util/common"

export function useControlClient(): ControlClient {
    const [searchParams] = useSearchParams()
    const client = extractGetParameter(searchParams, ["caller"])?.toLowerCase() || ControlClient.Web

    return client as ControlClient
}
