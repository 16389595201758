import { MsalProvider } from "@azure/msal-react"
import React from "react"
import ReactDOM from "react-dom"
import { IntlProvider } from "react-intl"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import WorkPointTheme from "@workpoint/components/lib/constants"
import { msalInstance } from "./clients/apiClientSetup"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import RouteApp from "./RouteApp"
import { persistor, store } from "./store/store"
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons"
import { ThemeProvider } from "@fluentui/react/lib/Theme"
import { initializeIcons } from "@fluentui/react/lib/Icons"

initializeFileTypeIcons(/* optional base url */)
initializeIcons(/* optional base url */)

ReactDOM.render(
    <React.StrictMode>
        <IntlProvider locale="en" defaultLocale="en">
            <MsalProvider instance={msalInstance}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ThemeProvider theme={WorkPointTheme}>
                            <BrowserRouter>
                                <RouteApp />
                            </BrowserRouter>
                        </ThemeProvider>
                    </PersistGate>
                </Provider>
            </MsalProvider>
        </IntlProvider>
    </React.StrictMode>,
    document.getElementById("root")
)
reportWebVitals()
