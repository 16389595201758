import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider"
import { useAppTitle } from "../../store/hooks/useAppTitle"
import {
    ClientType,
    ISPUserCultureAndTime,
    RuntimeContext
} from "@workpoint/components/lib/models/ProcessInstance"
import { Process } from "@workpoint/components/lib/components/process/Process"
import { eventBus, EventDetails, TeamsActivity } from "@workpoint/components/lib/clients/EventBus"
import { getspHostUrl } from "../../util/common"
import { UnauthorizedError } from "@workpoint/components/lib/components/common/UnauthorizedError"
import { GenericError } from "@workpoint/components/lib/components/common/GenericError"
import { useMsal } from "@azure/msal-react"

export const ProcessViewer: React.FC = () => {
    const intl = useIntl()
    const { accounts, instance } = useMsal()

    const { apiClient } = useApiClient()

    let element
    var params = new URLSearchParams(window.location.search)
    const solutionUrl = getspHostUrl() ?? ""
    const businessModuleId = getProperty(params, "businessmoduleid") ?? ""
    const entityIdParam = getProperty(params, "entityid") ?? ""
    const entityId = entityIdParam ? parseInt(entityIdParam) : undefined
    const locale = getProperty(params, "locale")
    const [runtimeContext, setRuntimeContext] = useState<RuntimeContext>()
    const [error, setError] = useState<any>()

    useAppTitle(
        intl.formatMessage({
            id: "process-title",
            defaultMessage: "Process"
        })
    )

    useEffect(() => {
        if (solutionUrl) {
            apiClient
                .getWP(`/Users/UserCultureAndTimeZoneInfo`)
                .then((userCulture: ISPUserCultureAndTime) => {
                    setRuntimeContext({
                        currentUICulture: locale ?? userCulture.CurrentUICulture,
                        currentCulture: userCulture.CurrentCulture,
                        solutionUrl,
                        businessModuleId,
                        entityId,
                        modernUIVersion: process.env.REACT_APP_VERSION ?? "",
                        fullPage: true,
                        client: ClientType.Embed,
                        timeZone: userCulture.TimeZoneInfo
                    })
                })
                .catch((e: any) => {
                    const date = new Date(Date.now())
                    setError({
                        ...e,
                        currentUser: accounts?.[0].username,
                        dateAndTime: `${date.toLocaleDateString(
                            locale ?? "en"
                        )} ${date.toLocaleTimeString(locale ?? "en")}`,
                        message: e.message
                    })
                })
        }
    }, [])

    useEffect(() => {
        if (runtimeContext) {
            const processId = getProperty(params, "processid") ?? undefined
            const processInstanceId = getProperty(params, "processinstanceid") ?? undefined
            if (processId || processInstanceId) {
                const processEventDetails = { parameters: {} } as EventDetails
                addProperty(params, "processId", processEventDetails)
                addProperty(params, "processInstanceId", processEventDetails)
                addProperty(params, "businessModuleId", processEventDetails)
                addProperty(params, "entityId", processEventDetails)
                addProperty(params, "list", processEventDetails)
                addProperty(params, "folder", processEventDetails)
                const items = getProperty(params, "items")
                if (items) {
                    try {
                        processEventDetails.items = JSON.parse(decodeURIComponent(items))
                    } catch (error) {}
                }

                const express = getProperty(params, "express")
                if (express) {
                    try {
                        processEventDetails.express = JSON.parse(decodeURIComponent(express))
                    } catch (error) {}
                }

                const teamsActivity = {} as TeamsActivity
                addProperty(params, "conversationId", teamsActivity)
                addProperty(params, "conversationType", teamsActivity)
                addProperty(params, "tenantId", teamsActivity)
                addProperty(params, "teamId", teamsActivity)
                addProperty(params, "channelId", teamsActivity)
                addProperty(params, "messageId", teamsActivity)
                addProperty(params, "parentMessageId", teamsActivity)
                if (Object.keys(teamsActivity).length > 0) {
                    processEventDetails.teamsActivity = teamsActivity
                }

                addProperty(
                    params,
                    "parambusinessmoduleid",
                    processEventDetails.parameters,
                    "BusinessModuleId"
                )
                addProperty(params, "BusinessModuleContentType", processEventDetails.parameters)
                addProperty(params, "EntitySiteList", processEventDetails.parameters)
                addProperty(params, "EntitySiteListContentType", processEventDetails.parameters)
                addProperty(params, "EntitySiteListFolder", processEventDetails.parameters)
                params.forEach((value, key) => {
                    if (
                        [
                            "sphosturl",
                            "businessmoduleid",
                            "parambusinessmoduleid",
                            "entityid",
                            "locale",
                            "processid",
                            "list",
                            "items",
                            "businessmodulecontenttype",
                            "entitysitelist",
                            "entitysitelistcontenttype",
                            "entitysitelistfolder",
                            "folder",
                            "processinstanceid",
                            "conversationid",
                            "conversationtype",
                            "tenantid",
                            "teamid",
                            "channelid",
                            "messageid",
                            "parentmessageid"
                        ].findIndex((n) => n.toLowerCase() === key.toLowerCase()) < 0
                    ) {
                        processEventDetails.parameters![key] = value
                    }
                })

                eventBus.runProcess(processEventDetails)
            }
        }
    }, [runtimeContext])

    if (error) {
        if (error.status === 401 || error.status === 403) {
            element = (
                <UnauthorizedError
                    error={error}
                    logout={() => {
                        instance.logoutRedirect()
                    }}
                />
            )
        } else {
            element = <GenericError error={error} />
        }
    } else {
        element = <>{runtimeContext && <Process runtimeContext={runtimeContext} />}</>
    }

    return element
}

const getProperty = (urlParameters: URLSearchParams, urlPropertyName: string) => {
    for (const [key, value] of urlParameters) {
        if (key.toLowerCase() === urlPropertyName.toLowerCase()) {
            return value
        }
    }
}

const addProperty = (
    urlParameters: URLSearchParams,
    urlPropertyName: string,
    obj: any,
    objPropertyName?: string
) => {
    var property = getProperty(urlParameters, urlPropertyName)
    if (property) {
        obj[objPropertyName ?? urlPropertyName] = property
    }
}
