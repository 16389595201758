import { useIntl } from "react-intl"
import { BaseApiProvider } from "@workpoint/components/lib/clients/BaseApiProvider"
import { InstallationControl } from "@workpoint/components/lib/components/installation/Installation"
import { useApiClientBase } from "../../store/hooks/useApiClient"
import { useAppTitle } from "../../store/hooks/useAppTitle"

export const InstallViewer = () => {
    const intl = useIntl()

    const apiClient = useApiClientBase()

    useAppTitle(
        intl.formatMessage({
            id: "workpoint-installation-title",
            defaultMessage: "WorkPoint Installation"
        })
    )

    return (
        <BaseApiProvider client={apiClient}>
            <InstallationControl apiClient={apiClient} />
        </BaseApiProvider>
    )
}
