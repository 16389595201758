import { useEffect } from "react"

export const useAppTitle = (title: string) => {
    useEffect(() => {
        const oldTitle = document.title
        title && (document.title = title)
        return () => {
            document.title = oldTitle
        }
    }, [title])
}
