import styled from "styled-components"
import { pageHeaderHeight } from "./PageHeader"

export const AppContainer = styled.div`
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

export const Container = styled.div`
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    margin: 0 auto;
    width: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
`

export const Content = styled.div`
    box-sizing: border-box;
    padding-left: 40px;
    padding-top: 40px;
    max-width: 100%;
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - ${pageHeaderHeight}px);
`
