import { Route, Routes } from "react-router-dom"
import AuthenticatedApp from "./components/common/AuthenticatedApp"
import { InstallViewer } from "./routes/Install/InstallViewer"
import { LoggedOut } from "./routes/LoggedOut"
import { NotificationViewer } from "./routes/Notification/NotificationViewer"
import { ProcessViewer } from "./routes/Process/ProcessViewer"
import { RelatedContentViewer } from "./routes/RelatedContent/RelatedContentViewer"
import { NotFound } from "./components/common/NotFound"
import { InstallSignin } from "./routes/Install/InstallSignin"
import { AppRoutes } from "./routes/routes"
import { withAITracking } from "@microsoft/applicationinsights-react-js"
import { reactPlugin } from "./appInsights"
import { ApiProvider } from "@workpoint/components/lib/clients/ApiProvider"
import { useAppSelector } from "./store/hooks"
import { globalSelector } from "./store/globalReducer"
import { useEffect, useState } from "react"
import { getApiClient, updateApiClient } from "./clients/apiClientSetup"
import { ApiClient } from "@workpoint/components/lib/clients/ApiClient"

const RouteApp = () => {
    let subDomain = ""

    const urlParams = new URLSearchParams(window.location.search.toLowerCase())
    const locale = urlParams.get("locale") || undefined

    try {
        const subDomainRoute = window.location.hostname
            .toLowerCase()
            .split(".")
            .filter((p) => p !== "www")

        if (subDomainRoute.length >= 3) {
            subDomain = subDomainRoute[0].split("-")[0].toLowerCase()
            if (subDomain !== "install") {
                subDomain = ""
            }
        }
    } catch (error) {
        console.log(
            `Could not determine sub domain route, defaulting to using app routes delimited by "/". It threw the following error:`,
            error
        )
    }

    /**
     * Returns available routes for the chosen sub-domain.
     * @param route sub-domain route
     */
    const getRoutes = (route: string) => {
        switch (route) {
            case "install":
                return <Route path={AppRoutes.Installer} element={<InstallViewer />} />
        }
    }

    const getLandingPageRoute = (route: string) => {
        let landingPage
        switch (route) {
            case "install":
                landingPage = <InstallSignin />
                break
            default:
                landingPage = <InstallSignin />
        }

        return <Route path={AppRoutes.LandingPage} element={landingPage} />
    }

    const ApiClientProvider = ({ children }: { children: React.ReactNode }) => {
        const { spHostUrl } = useAppSelector(globalSelector)
        const [prevSpHostUrl, setPrevSpHostUrl] = useState(spHostUrl)
        const [apiClient, setApiClient] = useState<ApiClient>(getApiClient(spHostUrl, locale))

        useEffect(() => {
            if (spHostUrl !== prevSpHostUrl) {
                setApiClient(updateApiClient(apiClient, spHostUrl))
                setPrevSpHostUrl(spHostUrl)
            }
        }, [spHostUrl])

        return <ApiProvider client={apiClient}>{children}</ApiProvider>
    }

    return (
        <Routes>
            {getLandingPageRoute(subDomain)}
            <Route path="/*" element={<AuthenticatedApp />}>
                {getRoutes(subDomain)}
                {!subDomain && (
                    <>
                        <Route
                            path={AppRoutes.Notifications}
                            element={
                                <ApiClientProvider>
                                    <NotificationViewer />
                                </ApiClientProvider>
                            }
                        />
                        <Route
                            path={AppRoutes.RelatedContent}
                            element={
                                <ApiClientProvider>
                                    <RelatedContentViewer />
                                </ApiClientProvider>
                            }
                        />
                        <Route
                            path={AppRoutes.ProcessBuilder}
                            element={
                                <ApiClientProvider>
                                    <ProcessViewer />
                                </ApiClientProvider>
                            }
                        />

                        <Route path={AppRoutes.Installer} element={<InstallViewer />} />
                    </>
                )}
            </Route>

            <Route path={AppRoutes.LoggedOut} element={<LoggedOut />} />
            <Route path="/*" element={<NotFound />}>
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    )
}

export default withAITracking(reactPlugin, RouteApp)
