import { useControlClient } from "../../store/hooks/useControlClient"
import { RelatedContent } from "@workpoint/components/lib/components/common/RelatedContent"
import { useSearchParams } from "react-router-dom"
import { extractGetParameter } from "../../util/common"
import { useAppTitle } from "../../store/hooks/useAppTitle"
import { useIntl } from "react-intl"

export const RelatedContentViewer = () => {
    const [searchParams] = useSearchParams()
    const client = useControlClient()
    const intl = useIntl()
    const siteUrl = extractGetParameter(searchParams, ["siteurl"]) || ""
    const itemUrl = extractGetParameter(searchParams, ["itemurl"]) || ""

    useAppTitle(
        intl.formatMessage({
            id: "related-content-title",
            defaultMessage: "Related content"
        })
    )

    return (
        <RelatedContent
            relatedContent={{
                ItemUrl: itemUrl,
                SiteUrl: siteUrl
            }}
            client={client}
        />
    )
}
